export default {
  methods: {
    getIgnorarCamposFiltros() {
      return [
        'id',
        'gid',
        'company',
        'company_id',
        'contador',
        'geom',
        'bdgd_versao_id',
        'atualizacao_id',
        'atualizacao_bdgd_via_sql_id',
        'importacao_id',
        'ais_id',
        'laudo_id'
      ];
    }
  }
};
