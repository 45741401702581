<template>
  <div id="visualizar-mapa"></div>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import dayjs from 'dayjs';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import GeoServerService from '@/services/GeoServerService';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import drawMixins from '@/mixins/leaflet/drawMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);
const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
const baseUrl = GeoServerService.getWmsBaseUrl();
const workspace = GeoServerService.getWorkspaceDefault();

const ignoredProperties = [
  'GEOM',
  'CONTADOR',
  'COMPANY',
  'COMPANY_ID',
  'MES_DADOS',
  'BDGD_VERSAO',
  'BDGD_VERSAO_ID'
];

export default {
  name: 'VisualizarMapa',
  mixins: [
    geocoderMixins,
    browserPrintMixins,
    scaleMixins,
    filtersMixins,
    drawMixins,
    dateFormatterMixins
  ],
  props: {
    entidadeBdgd: {
      type: String,
      required: true
    },
    bdgdA: {
      type: Object,
      required: true
    },
    bdgdB: {
      type: Object,
      required: true
    },
    dadosEntidadeBdgdA: {
      type: Object,
      required: true
    },
    dadosEntidadeBdgdB: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    map: null,
    mapaId: 'visualizar-mapa',
    layerControl: null,
    layers: {},
    legendControl: null,
    legendDiv: null
  }),
  mounted() {
    this.configuraMapa(4);
    // Coloca o centro do mapa próximo a capital onde está a distribuidora
    let [longitude, latitude] =
      this.$store.getters.getCompanyCapitalCoordinates;
    this.setView(latitude, longitude);
    this.map.setZoom(this.zoom);
    this.addBasesLayers();
    this.criaLegenda();
  },
  computed: {
    zoom() {
      const entidade = this.entidadeBdgd.toUpperCase();
      return entidade === 'ARAT' || entidade === 'CONJ' ? 6 : 15;
    },
    geoserverCqlFilterBdgdA() {
      return this.dadosEntidadeBdgdA.geoserverCqlFilter;
    },
    geoserverCqlFilterBdgdB() {
      return this.dadosEntidadeBdgdB.geoserverCqlFilter;
    },
    coordenadas() {
      let [longitude, latitude] =
        this.$store.getters.getCompanyCapitalCoordinates;
      const items =
        this.dadosEntidadeBdgdA.dados.length >= 1
          ? this.dadosEntidadeBdgdA.dados
          : this.dadosEntidadeBdgdB.dados;
      if (!(items && items.length >= 1)) {
        return {
          longitude: Number(longitude),
          latitude: Number(latitude)
        };
      }
      if (items[0]['latitude'] && items[0]['longitude']) {
        latitude = items[0]['latitude'];
        longitude = items[0]['longitude'];
      } else if (items[0]['latitude1'] && items[0]['longitude1']) {
        latitude = items[0]['latitude1'];
        longitude = items[0]['longitude1'];
      } else if (items[0]['latitude2'] && items[0]['longitude2']) {
        latitude = items[0]['latitude2'];
        longitude = items[0]['longitude2'];
      }
      return {
        longitude: Number(longitude),
        latitude: Number(latitude)
      };
    }
  },
  methods: {
    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Visualizar Mapa').addTo(this.map);
      this.configDrawControl(L);
    },
    setView(latitude = -2.5606242, longitude = -44.4016494) {
      this.map.setView([latitude, longitude]);
    },
    getSelectedLayersNames() {
      return Object.keys(this.layers).filter((layerName) =>
        this.map.hasLayer(this.layers[layerName])
      );
    },
    configDrawControl(L) {
      let { drawnItems, drawControl } = this.createDrawControl(L);

      this.map.addLayer(drawnItems);
      this.map.addControl(drawControl);

      this.map.on('draw:created', ({ layer: polygon }) => {
        drawnItems.addLayer(polygon);
        // Efetua o download os arquivos com as feições dentro do poligono criado
        this.getSelectedLayersNames().forEach((selectedLayer) => {
          const arrLatLngs = polygon.getLatLngs();
          this.downloadCsvFeaturesWithinPolygon(selectedLayer, arrLatLngs[0]);
        });
      });

      this.map.on('draw:edited', ({ layers: polygons }) => {
        polygons.eachLayer((polygon) => {
          // Efetua o download os arquivos com as feições dentro do poligono criado
          this.getSelectedLayersNames().forEach((selectedLayer) => {
            const arrLatLngs = polygon.getLatLngs();
            this.downloadCsvFeaturesWithinPolygon(selectedLayer, arrLatLngs[0]);
          });
        });
      });
    },
    downloadCsvFeaturesWithinPolygon(layerName, polygonLatLngs) {
      const cqlFilter =
        layerName === 'A'
          ? this.dadosEntidadeBdgdA.geoserverCqlFilter
          : this.dadosEntidadeBdgdB.geoserverCqlFilter;

      const ogcFilter =
        layerName === 'A'
          ? this.dadosEntidadeBdgdA.geoserverOgcFilter
          : this.dadosEntidadeBdgdB.geoserverOgcFilter;

      const geoserverLayer =
        layerName === 'A'
          ? this.dadosEntidadeBdgdA.geoserverLayer
          : this.dadosEntidadeBdgdB.geoserverLayer;

      const promise = this.isCqlFilter()
        ? GeoServerService.downloadCsvFeaturesWithinPolygon(
            geoserverLayer,
            polygonLatLngs,
            cqlFilter,
            0 // maxFeatures
          )
        : GeoServerService.downloadCsvFeaturesWithinPolygonUsingOgcFilter(
            geoserverLayer,
            polygonLatLngs,
            ogcFilter,
            0 // maxFeatures
          );

      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      promise
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeArquivo = `bdgd-${layerName.toLowerCase()}-feicoes-selecionadas-${timestamp}.csv`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo CSV com as feições selecionadas.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },
    addBasesLayers() {
      this.addBaseLayer('A');
      this.addBaseLayer('B');
      const { longitude, latitude } = this.coordenadas;
      if (longitude && latitude) {
        this.setView(latitude, longitude);
        this.map.setZoom(this.zoom);
      }
    },
    addBaseLayer(base) {
      const cqlFilter = this.getCqlFilter(base);
      const ogcFilter = this.getOgcFilter(base);
      const geoserverLayer = this.getGeoServerLayer(base);
      const sldBody = this.getSldBody(base);

      // Se o layer já existe, remove-o
      if (this.layers[base]) {
        this.layerControl.removeLayer(this.layers[base]);
      }

      const MySource = wms.Source.extend({
        showFeatureInfo: (latlng, info) => {
          if (info.includes('no features were found')) {
            return;
          }

          let arrInfo = info.split('\n');
          let stringInfo = '';

          arrInfo.forEach((element, key) => {
            if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
              return;

            if (element.slice(-4) == 'null') return;

            element = element.split('=');
            let property = element[0].trim().toUpperCase();
            if (ignoredProperties.includes(property)) return;

            element = property + ': ' + element[1].trim();
            stringInfo += `${element}<br>`;
          });

          L.popup()
            .setLatLng(latlng)
            .setContent(`<div id="div-popup">${stringInfo}</div>`)
            .openOn(this.map);
        }
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true, // Funcionalidade de clicar na feição e obter os dados,
        sld_body: sldBody
      };

      // Verifica qual tipo de filtro será utilizado, CQL ou OGC e configura o parâmetro correto
      this.isCqlFilter()
        ? (config.cql_filter = cqlFilter)
        : (config.filter = ogcFilter);

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      this.layers[base] = source.getLayer(`${workspace}:${geoserverLayer}`);

      // Adiciona o layer no mapa
      this.layerControl.addOverlay(
        this.layers[base],
        `BDGD ${base} :: ${this.entidadeBdgd.toUpperCase()}`
      );
      this.layers[base].addTo(this.map);
    },
    getCqlFilter(base) {
      return base === 'A'
        ? this.dadosEntidadeBdgdA.geoserverCqlFilter
        : this.dadosEntidadeBdgdB.geoserverCqlFilter;
    },
    getOgcFilter(base) {
      return base === 'A'
        ? this.dadosEntidadeBdgdA.geoserverOgcFilter
        : this.dadosEntidadeBdgdB.geoserverOgcFilter;
    },
    getGeoServerLayer(base) {
      return base === 'A'
        ? this.dadosEntidadeBdgdA.geoserverLayer
        : this.dadosEntidadeBdgdB.geoserverLayer;
    },
    getLayerColor(base) {
      let layerColor = base === 'A' ? this.bdgdA.cor : this.bdgdB.cor;
      if (layerColor === null) {
        layerColor = base === 'A' ? '#9C27B0' : '#E65100';
      }
      return layerColor;
    },
    getSldBody(base) {
      const bdgd =
        base === 'A' ? this.dadosEntidadeBdgdA : this.dadosEntidadeBdgdB;
      const { entidadeTipoLinha, entidadeTipoPoligono } = bdgd;
      if (entidadeTipoLinha) return this.getSldBodyLine(base);
      if (entidadeTipoPoligono) return this.getSldBodyPolygon(base);
      return this.getSldBodyPoint(base);
    },
    getSldBodyPoint(base) {
      const layerColor = this.getLayerColor(base);
      const geoserverLayer = this.getGeoServerLayer(base);
      let sldBody = `
<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0"
    xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
    xmlns="http://www.opengis.net/sld"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name>${workspace}:${geoserverLayer}</Name>
    <UserStyle>
      <Title>${workspace}:${geoserverLayer}</Title>
      <FeatureTypeStyle>
        <Rule>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">${layerColor}</CssParameter>
                </Fill>
              </Mark>
              <Size>6</Size>
            </Graphic>
          </PointSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>
`;
      return sldBody;
    },
    getSldBodyLine(base) {
      const layerColor = this.getLayerColor(base);
      const geoserverLayer = this.getGeoServerLayer(base);
      let sldBody = `
<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0"
    xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
    xmlns="http://www.opengis.net/sld"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name>${workspace}:${geoserverLayer}</Name>
    <UserStyle>
      <Title>${workspace}:${geoserverLayer}</Title>
      <FeatureTypeStyle>
        <Rule>
          <LineSymbolizer>
            <Stroke>
              <CssParameter name="stroke">${layerColor}</CssParameter>
              <CssParameter name="stroke-width">3</CssParameter>
            </Stroke>
          </LineSymbolizer>
       	</Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>
`;
      return sldBody;
    },
    getSldBodyPolygon(base, opacity = 0.5) {
      const layerColor = this.getLayerColor(base);
      const geoserverLayer = this.getGeoServerLayer(base);
      let sldBody = `
<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0"
    xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
    xmlns="http://www.opengis.net/sld"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name>${workspace}:${geoserverLayer}</Name>
    <UserStyle>
      <Title>${workspace}:${geoserverLayer}</Title>
      <FeatureTypeStyle>
        <Rule>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">${layerColor}</CssParameter>
              <CssParameter name="fill-opacity">${opacity}</CssParameter>
            </Fill>
            <Stroke>
              <CssParameter name="stroke">#FFFFFF</CssParameter>
              <CssParameter name="stroke-width">2</CssParameter>
            </Stroke>
          </PolygonSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>
`;
      return sldBody;
    },
    criaLegenda() {
      if (!this.legendControl) {
        this.legendControl = L.control({ position: 'bottomright' });
        this.legendDiv = L.DomUtil.create('div', 'mapa-info mapa-legend');
      }

      let html = [];
      html.push(this.criaHtmlLegenda('A'));
      html.push(this.criaHtmlLegenda('B'));

      this.legendDiv.innerHTML = html.join('<br>');
      this.legendControl.onAdd = () => this.legendDiv;
      this.legendControl.addTo(this.map);
    },
    criaHtmlLegenda(base) {
      const corFeicoes = this.getLayerColor(base);
      const bgColor1 = 'white';
      const bgColor2 = corFeicoes;
      const html = `
  <div style="display:flex; align-items: center; margin: 3px 0px">
    <span style="height: 23px; background-color: ${bgColor1}">
      <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${corFeicoes}"></div>
    </span>&nbsp;BDGD ${base}
  </div>
`;
      return html;
    }
  }
};
</script>

<style>
#visualizar-mapa {
  height: 80vh;
  z-index: 0;
}
#div-popup {
  max-height: 200px;
  overflow-y: auto;
}
.mapa-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.mapa-info h4 {
  margin: 0 0 5px;
  color: #777;
}
.mapa-legend {
  line-height: 18px;
  color: #555;
  max-height: 225px !important;
  overflow-y: auto !important;
}
.mapa-legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
