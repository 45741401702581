<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-map-marker-distance"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Comparar Entidades Geográficas da BDGD
              </div>
            </template>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="4">
                    <v-card tile>
                      <v-subheader> BDGD A </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <select-all-bdgd-versions
                          label="Versão da BDGD"
                          :companyId="companyId"
                          @bdgdVersionSelected="
                            (event) => handleBdgdVersionSelected('A', event)
                          "
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card tile>
                      <v-subheader> BDGD B </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <select-all-bdgd-versions
                          label="Versão da BDGD"
                          :companyId="companyId"
                          @bdgdVersionSelected="
                            (event) => handleBdgdVersionSelected('B', event)
                          "
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card tile>
                      <v-subheader> Entidade a ser plotada </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <v-select
                          v-model="entidadeSelecionada"
                          :items="entidadesImportadasNasDuasVersoes"
                          label="Entidade"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="exibirFiltros"
            :disabled="!podeExibirFiltros"
          >
            Exibir Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="camposEntidade.length">
        <v-col cols="6">
          <campos-filtros
            ref="refCamposFiltrosBdgdA"
            :campos="camposEntidade"
            :baseDados="entidade"
            @loading="loading['A'] = $event"
          />
        </v-col>
        <v-col cols="6">
          <campos-filtros
            ref="refCamposFiltrosBdgdB"
            :campos="camposEntidade"
            :baseDados="entidade"
            @loading="loading['B'] = $event"
          />
        </v-col>
      </v-row>
      <v-row v-if="camposEntidade.length">
        <v-col cols="6">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="() => getDadosEntidade('A')"
            :loading="loading['A']"
          >
            Filtrar na BDGD A
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="() => getDadosEntidade('B')"
            :loading="loading['B']"
          >
            Filtrar na BDGD B
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="exibirMapa">
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-eye-outline"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                BDGD A {{ bdgdA.versaoTxt }} x BDGD B {{ bdgdB.versaoTxt }} ::
                entidade {{ entidadeSelecionada }}
              </div>
            </template>
            <v-row>
              <v-col
                cols="12"
                v-if="!(loading['A'] || loading['B'])"
              >
                <v-alert
                  outlined
                  type="info"
                  color="blue"
                  class="mt-4 mb-4 py-1 px-1"
                >
                  <span v-if="dadosEntidadeBdgdA.numRegistrosEntidade">
                    BDGD A: a consulta retorna no máximo
                    <strong>{{ limit | parseNumberToIntegerBR }}</strong> de um
                    total de
                    <strong>
                      {{
                        dadosEntidadeBdgdA.numRegistrosEntidade
                          | parseNumberToIntegerBR
                      }}
                    </strong>
                    registros na entidade. Sua consulta retornou
                    <strong>
                      {{
                        dadosEntidadeBdgdA.numRegistros | parseNumberToIntegerBR
                      }}
                    </strong>
                    registros.
                    <br />
                  </span>
                  <span v-if="dadosEntidadeBdgdB.numRegistrosEntidade">
                    BDGD B: a consulta retorna no máximo
                    <strong>{{ limit | parseNumberToIntegerBR }}</strong> de um
                    total de
                    <strong>
                      {{
                        dadosEntidadeBdgdB.numRegistrosEntidade
                          | parseNumberToIntegerBR
                      }}
                    </strong>
                    registros na entidade. Sua consulta retornou
                    <strong>
                      {{
                        dadosEntidadeBdgdB.numRegistros | parseNumberToIntegerBR
                      }}
                    </strong>
                    registros.
                  </span>
                </v-alert>
                <visualizar-mapa
                  :entidadeBdgd="entidade"
                  :bdgdA="bdgdA"
                  :bdgdB="bdgdB"
                  :dadosEntidadeBdgdA="dadosEntidadeBdgdA"
                  :dadosEntidadeBdgdB="dadosEntidadeBdgdB"
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions';
import VisualizarMapa from './VisualizarMapa';
import CamposFiltros from '@/components/relatorios/CamposFiltros';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';

export default {
  name: 'CompararVersoesNoMapa',
  mixins: [camposFiltrosMixins],
  components: {
    SelectAllBdgdVersions,
    CamposFiltros,
    VisualizarMapa
  },
  data: () => ({
    bdgdA: {},
    bdgdB: {},
    entidadesBdgdA: [],
    entidadesBdgdB: [],
    entidadeSelecionada: null,
    camposEntidade: [],
    loading: {
      A: false,
      B: false
    },
    limit: 1000,
    dadosEntidadeBdgdA: {},
    dadosEntidadeBdgdB: {}
  }),
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },
    bdgdAVersaoId() {
      return this.bdgdA.id;
    },
    bdgdBVersaoId() {
      return this.bdgdB.id;
    },
    bdgdAValida() {
      return !!this.bdgdAVersaoId;
    },
    bdgdBValida() {
      return !!this.bdgdBVersaoId;
    },
    entidadesImportadasNasDuasVersoes() {
      const entidadesBdgdA = this.entidadesBdgdA.map(
        (entidade) => entidade.descricao
      );
      const entidadesBdgdB = this.entidadesBdgdB.map(
        (entidade) => entidade.descricao
      );
      if (entidadesBdgdA.length === 0 || entidadesBdgdB.length === 0) return [];
      return entidadesBdgdA.filter((entidade) =>
        entidadesBdgdB.includes(entidade)
      );
    },
    podeExibirFiltros() {
      return (
        this.bdgdAValida !== null &&
        this.bdgdBValida !== null &&
        this.bdgdAVersaoId !== this.bdgdBVersaoId &&
        this.entidadeSelecionada !== null
      );
    },
    entidade() {
      if (!this.entidadeSelecionada) return null;
      return this.entidadeSelecionada.toLowerCase();
    },
    exibirMapa() {
      return !!(
        this.camposEntidade.length &&
        (this.dadosEntidadeBdgdA.geoserverOgcFilter ||
          this.dadosEntidadeBdgdB.geoserverOgcFilter)
      );
    }
  },
  methods: {
    handleBdgdVersionSelected(base, bdgdVersion) {
      if (base === 'A') {
        this.bdgdA = bdgdVersion;
      } else {
        this.bdgdB = bdgdVersion;
      }
    },
    getEntidadesGeograficas(base, dataRegistro, versao) {
      BasesDadosService.getBasesDadosGeograficasLiberadas(
        this.companyId,
        dataRegistro,
        versao
      )
        .then((response) => {
          const entidades = response.data;
          if (!entidades.length) {
            this.$toast.warning(
              'Nenhuma entidade geográfica encontrada na versão da BDGD selecionada.',
              '',
              { position: 'topRight' }
            );
            return;
          }
          if (base === 'A') {
            this.entidadesBdgdA = entidades;
          } else {
            this.entidadesBdgdB = entidades;
          }
          this.entidadeSelecionada = null;
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar as entidades geográficas para a versão da BDGD selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },
    getCamposEntidade() {
      BasesDadosService.getBasesDadosCampos(this.entidade)
        .then((camposEntidade) => {
          const ignorarCampos = this.getIgnorarCamposFiltros();
          this.camposEntidade = camposEntidade.filter(
            (campo) => !ignorarCampos.includes(campo.column)
          );
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao recuperar campos da entidade selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },
    getFiltros(base) {
      let filtros = this.$refs.refCamposFiltrosBdgdA
        ? this.$refs.refCamposFiltrosBdgdA.exportCampos()
        : {};
      if (base === 'B') {
        filtros = this.$refs.refCamposFiltrosBdgdB
          ? this.$refs.refCamposFiltrosBdgdB.exportCampos()
          : {};
      }
      return filtros;
    },
    getDadosEntidade(base) {
      const filtros = this.getFiltros(base);
      const bdgdVersaoId =
        base === 'A' ? this.bdgdAVersaoId : this.bdgdBVersaoId;
      this.loading[base] = true;
      EntidadesBdgdService.getDadosEntidade(
        this.entidade,
        bdgdVersaoId,
        filtros,
        this.limit
      )
        .then(({ data }) => {
          if (base === 'A') {
            this.dadosEntidadeBdgdA = data;
          } else {
            this.dadosEntidadeBdgdB = data;
          }
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao recuperar as linhas importadas para a entidade ${this.entidadeSelecionada}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading[base] = false));
    },
    exibirFiltros() {
      this.getCamposEntidade();
    }
  },
  watch: {
    bdgdAVersaoId() {
      if (!this.bdgdAVersaoId) return;
      const { mes, versao } = this.bdgdA;
      this.getEntidadesGeograficas('A', mes, versao);
    },
    bdgdBVersaoId() {
      if (!this.bdgdBVersaoId) return;
      const { mes, versao } = this.bdgdB;
      this.getEntidadesGeograficas('B', mes, versao);
    }
  }
};
</script>
